import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://cdn.douniuniu.com.cn/douniuniu-landing/logo.svg"
          className="App-logo"
          alt="logo"
        />
        <p>一个专注于全自动运营的电商浏览器插件</p>
        <p>尽情期待</p>
      </header>
    </div>
  );
}

export default App;
